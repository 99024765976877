<template>
    <div class="login row no-gutters">
        <div class="col-md-4">
            <login-form @submit="submit" />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import LoginForm from 'components/LoginForm'
const hubENV = process.env.VUE_APP_HUB_ENV;

export default {
    name: 'Login',
    data() {
        return {
            test: hubENV
        }
    },
    methods: {
        submit(payload) { // Do validation

            // if (window.location.href == 'https://hub.getgrab.com/login' && (payload.email.includes('@getgrab.com') || payload.email.includes('@servy.us'))) {
            //     if (payload.email != 'nick@getgrab.com') {
            //         window.location = "https://hubstage.getgrab.com";
            //         return
            //     }
            // }
            this.login(payload)
                .then(data => {
                    if (!data.accessToken) {
                        if (data.exception != undefined && data.exception.length > 0) {
                            return this.toast(data.exception, 'error')

                        } else {
                            return this.toast('We could not log you in.', 'error')
                        }
                    }

                    this.toast('Logged in', 'success')
                    if (data.deliveryPartner) {
                        return this.$router.push({name: 'delivery'})
                    } else {
                        return this.$router.push({name: 'store'})
                    }
                })
        },

        ...mapActions(['login']),
    },

    components: {
        LoginForm,
    },
}
</script>

<style lang="scss" scoped>
@import '~scss/variables';
@import '~scss/mixins';
.login {
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: spacing(xl);
}
</style>
