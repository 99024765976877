// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/g_hub_white.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login-form {\n  display: flex;\n  justify-content: center;\n}\n.login-form__logo-container {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 42px;\n}\n.login-form__logo {\n  width: 228px;\n  height: 110px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n}\n.login-form__form {\n  max-width: 500px;\n  padding: 18px;\n  background-color: #fff;\n  border-radius: 3px;\n}\n.login-form__title {\n  margin-bottom: 18px;\n  font-weight: bold;\n}\n.login-form__input-container {\n  margin-bottom: 12px;\n}\n.login-form__button {\n  padding: 6px 18px;\n  margin-bottom: 24px;\n  background-color: #93BD20;\n  color: #fff;\n  border: 0;\n  border-radius: 3px;\n  width: 210px;\n  height: 33px;\n}\n.login-form__link {\n  color: #93BD20;\n}\n.login-form__input {\n  margin-top: 12px;\n}", ""]);
// Exports
module.exports = exports;
