<template>
    <div class="login-form row">
        <div class="login-form__logo-container col-12">
            <div class="login-form__logo"/>
        </div>

        <form class="login-form__form shadow--1 col-12">
            <div class="login-form__title">
                Sign in to continue
            </div>

            <div class="login-form__input-container">
                <form-input
                    v-model="form.email"
                    class="login-form__input"
                    type="email"
                    placeholder="Email"
                    autofocus
                />

                <form-input
                    v-model="form.password"
                    class="login-form__input"
                    type="password"
                    placeholder="Password"
                />
            </div>

            <div>
                <button
                    class="login-form__button"
                    @click.prevent="$emit('submit', form)"
                >Sign in</button>
            </div>
            <a
                class="login-form__link"
                href="/resetPassword"
            >Forgot your password?</a>
        </form>
    </div>
</template>

<script>
import FormInput from 'components/cleverly/FormInput'

export default {
    name: 'LoginForm',
    components: {
        FormInput,
    },
    data: () => ({
        form: {
            email: '',
            password: '',
        },
    }),
}
</script>

<style lang="scss">
@import '~scss/variables';
@import '~scss/mixins';

.login-form {
    display: flex;
    justify-content: center;

    &__logo-container {
        display: flex;
        justify-content: center;
        margin-bottom: spacing(xl);
    }

    &__logo {
        width: 228px;
        height: 110px;
        background-image: url('../img/g_hub_white.svg');
        background-size: cover;
    }

    &__form {
        max-width: 500px;
        padding: spacing(sm);
        background-color: #fff;
        border-radius: 3px;        
    }

    &__title {
        margin-bottom: spacing(sm);
        font-weight: bold;
    }

    &__input-container {
        margin-bottom: spacing(xs);
    }

    &__button {
        padding: spacing(xxs) spacing(sm);
        margin-bottom: spacing(md);
        background-color: $brand_color;
        color: #fff;
        border: 0;
        border-radius: 3px;
        width: 210px;
        height: 33px;
    }

    &__link {
        color: $brand_color;
    }

    &__input {
        margin-top: spacing(xs);
    }
}
</style>
