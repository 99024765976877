var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-form row" }, [
    _vm._m(0),
    _c("form", { staticClass: "login-form__form shadow--1 col-12" }, [
      _c("div", { staticClass: "login-form__title" }, [
        _vm._v(" Sign in to continue "),
      ]),
      _c(
        "div",
        { staticClass: "login-form__input-container" },
        [
          _c("form-input", {
            staticClass: "login-form__input",
            attrs: { type: "email", placeholder: "Email", autofocus: "" },
            model: {
              value: _vm.form.email,
              callback: function ($$v) {
                _vm.$set(_vm.form, "email", $$v)
              },
              expression: "form.email",
            },
          }),
          _c("form-input", {
            staticClass: "login-form__input",
            attrs: { type: "password", placeholder: "Password" },
            model: {
              value: _vm.form.password,
              callback: function ($$v) {
                _vm.$set(_vm.form, "password", $$v)
              },
              expression: "form.password",
            },
          }),
        ],
        1
      ),
      _c("div", [
        _c(
          "button",
          {
            staticClass: "login-form__button",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.$emit("submit", _vm.form)
              },
            },
          },
          [_vm._v("Sign in")]
        ),
      ]),
      _c(
        "a",
        { staticClass: "login-form__link", attrs: { href: "/resetPassword" } },
        [_vm._v("Forgot your password?")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "login-form__logo-container col-12" }, [
      _c("div", { staticClass: "login-form__logo" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }